import { useMemo, useContext, useState, useEffect, useCallback } from 'react';
import { UserContext, NotificationContext, InternalLink } from '@mdc/ui';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'react-bootstrap';
import NotificationPermission from './NotificationPermission';
import CHECKBOXES_FIELDS from './CheckboxFields';
import { BackendService, logService, UtilsFunctions } from '@mdc/services';
import { NOTIFICATION_TYPES } from '@mdc/constants';
import axios from 'axios';
import ContentLoader from 'react-content-loader';

import './NotificationSettings.scss';
const NOTIFICATION_URL = 'https://notifications-qa.metadefender.opswat.com';

const NotificationSettings = () => {
    const [currentSubscription, setCurrentSubscription] = useState([]);
    const [previousSubscription, setPreviousSubscription] = useState([]);
    const [shouldReset, setShouldReset] = useState(undefined);
    const [isUpdatingTags, setIsUpdatingTags] = useState(true);
    const [tagsDefinitions, setTagsDefinitions] = useState(undefined);
    const userContext = useContext(UserContext);
    const { t, ready } = useTranslation();
    const { notify } = useContext(NotificationContext);

    useEffect(() => {
        // Get Notifications tags definitions using websocket
        if (!userContext) {
            return;
        }

        const visibilityFilter = userContext?.data?.organization ? 'organization' : 'user';

        (async () => {
            try {
                // Get tags definitions
                const response = await axios.get(`${NOTIFICATION_URL}/api/get-tags-definition?visibilityFilter=${visibilityFilter}`);
                if (response && response.data) {
                    setTagsDefinitions(response.data);
                }
            } catch (err) {
                logService.error(err);
                notify({
                    message: t('Request failed. Please try again!'),
                    type: NOTIFICATION_TYPES.CRITICAL
                });
            }
        })();
    }, [userContext]);

    useEffect(() => {
        // Throttle requests
        let discard = false;
        setIsUpdatingTags(true);
        const timeout = setTimeout(() => {
            (async () => {
                try {
                    let response;
                    if (userContext?.data?.organization) {
                        response = await BackendService.getNotificationSubscribedTags({
                            userId: userContext.data.sso_user_id,
                            organizationId: userContext.data.organization.organization_id
                        })[0];
                    } else {
                        response = await axios.get(`${NOTIFICATION_URL}/api/check-user-subscription?userId=${userContext.data.sso_user_id}`);
                    }

                    if (response.data && !discard) {
                        setCurrentSubscription(response.data);
                        setPreviousSubscription(response.data);
                        setIsUpdatingTags(false);
                    }
                } catch (e) {
                    logService.error(e);
                    notify({
                        message: t('Request failed. Please try again!'),
                        type: NOTIFICATION_TYPES.CRITICAL,
                        alwaysVisible: true
                    });
                }
            })();
        }, 1000);

        return function () {
            discard = true;
            clearTimeout(timeout);
        };
    }, [userContext, shouldReset]);

    const checkPrevAndCurrentAreEqual = (prevSubscription, currSubscription) => {
        if (prevSubscription.length > currSubscription.length) {
            return prevSubscription.filter((tag) => !currSubscription.some((tag2) => tag2.tag === tag.tag && tag2.delivery.length === tag.delivery.length)).length ? false : true;
        }

        return currSubscription.filter((tag) => !prevSubscription.some((tag2) => tag2.tag === tag.tag && tag2.delivery.length === tag.delivery.length)).length ? false : true;
    };

    const isSaveDisabled = useMemo(() => {
        return isUpdatingTags || checkPrevAndCurrentAreEqual(currentSubscription, previousSubscription);
    }, [currentSubscription, previousSubscription, isUpdatingTags]);

    const updateTags = useCallback((subscription) => {
        const sendUpdatedSubscriptions = subscription.filter((tag) => tag.delivery.length > 0);
        (async () => {
            try {
                let response;

                if (hasEditAccess && userContext?.data?.organization) {
                    response = await BackendService.postNotificationSubscribedTags({
                        userId: userContext.data.sso_user_id,
                        organizationId: userContext.data.organization.organization_id,
                        body: sendUpdatedSubscriptions
                    })[0];
                } else if (hasEditAccess && !userContext?.data?.organization) {
                    // it's regular user, call serverless to save settings
                    response = await axios.post(`${NOTIFICATION_URL}/api/subscribe-user`, {
                        userId: userContext.data.sso_user_id,
                        tags: sendUpdatedSubscriptions
                    });
                }

                if (response.data) {
                    setCurrentSubscription(response.data.subscribedTags);
                    setPreviousSubscription(response.data.subscribedTags);

                    notify({
                        message: t('Notification settings successfully updated!'),
                        type: NOTIFICATION_TYPES.SUCCESS
                    });
                }
            } catch (e) {
                logService.error(e);
                notify({
                    message: t('Request failed. Please try again!'),
                    type: NOTIFICATION_TYPES.CRITICAL
                });
            }
        })();
    }, [userContext, currentSubscription]);

    const handleCancel = useCallback((evt) => {
        evt.preventDefault();
        setIsUpdatingTags(false);
        setShouldReset(new Date().getTime());
    }, []);

    const hasEditAccess = useMemo(() => {
        // is part of an Organization and is admin OR
        // is not part of an Organization
        // eslint-disable-next-line camelcase
        return (userContext.isOrganizationAdmin && userContext?.data?.sso_user_id && userContext?.data?.organization?.organization_id) || !userContext?.data?.organization;
    }, [userContext]);

    const toggleSelectedOptions = (options) => {
        setCurrentSubscription(() => {
            // only add the items that have `delivery` set and are unique
            const prevOption = options?.filter((tag, index) => tag.delivery.length > 0 && options?.indexOf(tag) === index);
            return [...prevOption];
        });
    };

    const getCorrectSubscriptionDelivery = (subscription, type) => subscription.delivery.some((method) => method === type)
        ? { ...subscription, delivery: subscription.delivery.filter((s) => s !== type) }
        : { ...subscription, delivery: [...subscription.delivery, type] };

    const getDeliveryMethod = (percentageTag, tempSubscription, type) => {
        const allowedDeliveryMethods = CHECKBOXES_FIELDS(t).map((field) => field.key);

        return percentageTag
            ? tempSubscription.filter((subscriptionTag) =>
                subscriptionTag.tag.includes(percentageTag) && subscriptionTag.delivery.some((distributionMethod) => allowedDeliveryMethods.includes(distributionMethod)))[0]?.delivery ?? [type]
            : [type];
    };

    const filterSuboptions = (tempSubscription, type, percentageTag, param) => {
        if (type === 'suboption') {
            tempSubscription = tempSubscription.filter((tag) => tag.tag !== param.tag);
        }
        tempSubscription = tempSubscription.map((subscription) => {
            if ((percentageTag && subscription.tag.includes(percentageTag)) || (!percentageTag && subscription.tag === param.tag)) {
                return getCorrectSubscriptionDelivery(subscription, type);
            }

            return subscription;
        });

        return tempSubscription;
    };

    const onOptionClick = (param, type) => {
        let tempSubscription = currentSubscription;
        const percentageTag = param.tag.match(/\d+%?/g) ? param.tag.replace(/-\d+%?/g, '') : null;

        if (tempSubscription?.some((tag) => tag.tag === param.tag)) {
            tempSubscription = filterSuboptions(tempSubscription, type, percentageTag, param);
        } else {
            if (type !== 'suboption' && tempSubscription?.some((tag) => tag.tag.includes(percentageTag))) {
                // if click on the primary checkbox and we do have some suboptions selected,
                // update their delivery method
                tempSubscription = tempSubscription
                    ?.map((subscription) => {
                        if (percentageTag && subscription.tag.includes(percentageTag)) {
                            return getCorrectSubscriptionDelivery(subscription, type);
                        }

                        return subscription;
                    });
            } else {
                // if we click on suboption OR no suboptions selected
                const deliveryMethod = getDeliveryMethod(percentageTag, tempSubscription, type);

                tempSubscription = [...tempSubscription, {
                    tag: param.tag,
                    description: param.description,
                    delivery: deliveryMethod
                }];
            }
        }

        toggleSelectedOptions(tempSubscription);
    };

    const tableDom = useMemo(() => {
        if (!tagsDefinitions) {
            return;
        }

        const { tags } = tagsDefinitions;
        const timeInterval = userContext?.data?.organization?.licenseLimitsTimeInterval || null;
        const timeIntervalRegex = new RegExp(`${timeInterval}`, 'i');

        const percentageTags = tags?.filter((tag) => tag.tag.match(/-\d+%?/g)).reduce((newTags, oldTag) => {
            const { tag, description } = oldTag;

            const tagName = tag.replace(/-\d+%?/g, '');
            const percentage = tag.match(/\d+%?/g);

            if (tag.includes(tagName) && timeIntervalRegex.test(tag)) {
                newTags[tagName] = newTags[tagName] || {};
                newTags[tagName].percentages = newTags[tagName].percentages || [];
                newTags[tagName].tagsList = newTags[tagName].tagsList || [];
                newTags[tagName].tag = newTags[tagName].tag || tag;
                newTags[tagName].description = newTags[tagName].description || description || 'No description';
                oldTag.percentage = percentage;

                newTags[tagName].percentages = newTags[tagName].percentages.concat(percentage);
                newTags[tagName].tagsList.push(oldTag);
            }

            /** Group alike tags. Outcome:
             * [
             *      description: String,
             *      percentages: [Array(strings)] "50%", "60%", "70%",
             *      tagsList   : [Array(Object)] [{tag: String, description: String, percentage: String}],
             *      tag        : String
             * ]
             */
            return newTags;
        }, []) || [];

        const processedTags = [...(tags?.filter((tag) => !tag.tag.match(/-\d+%?/g)) || []), ...Object.values(percentageTags)];

        return processedTags.map((tag) => {
            // eslint-disable-next-line camelcase
            if (tag?.visible_on_ui === false || (!tag?.tag && !tag?.description)) {
                return;
            }

            return <NotificationPermission key={tag.id} tag={tag} hasEditAccess={hasEditAccess} subscription={currentSubscription} onOptionClick={onOptionClick} isUpdating={isUpdatingTags} />;
        });
    }, [hasEditAccess, currentSubscription, isUpdatingTags, tagsDefinitions, userContext]);

    const footerDom = useMemo(() => {
        if (!tagsDefinitions) {
            return;
        }

        return <div className='d-flex flex-row flex-wrap align-items-center mt-4'>
            <Button disabled={isSaveDisabled} onClick={updateTags.bind(null, currentSubscription)}>
                {t('Save')}
            </Button>
            <InternalLink onClick={handleCancel} to='/' className='ml-3'>{t('Cancel')}</InternalLink>
        </div>;
    }, [handleCancel, isSaveDisabled, updateTags, currentSubscription, tagsDefinitions, t]);

    const permissionDom = useMemo(() => {
        if (isUpdatingTags) {
            const codeArray = new Array(6).fill(null);
            return codeArray.map((index) => (
                <Row className='tagLi' key={index}>
                    <Col xs={12} md={6} className='name'>
                        <ContentLoader
                            className='lineContentLoader'
                            height={20}
                            width={'100%'}
                            viewBox={'0 0 200 20'}
                        >
                            <rect x="0" y="0" rx="3" ry="3" width={`${UtilsFunctions.getRandomInt(50, 70)}%`} height="15" />
                        </ContentLoader>
                        <div className='descriptionWrapper'>
                            <ContentLoader
                                className='lineContentLoader'
                                height={15}
                                width={'100%'}
                                viewBox={'0 0 200 15'}
                            >
                                <rect x="0" y="0" rx="3" ry="3" width={`${UtilsFunctions.getRandomInt(20, 50)}%`} height="10" />
                            </ContentLoader>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className='actions'>
                        <div className='options'>
                            <ContentLoader
                                className='lineContentLoader'
                                height={20}
                                width={'100%'}
                                viewBox={'0 0 200 20'}
                            >
                                <rect x="0" y="0" rx="3" ry="3" width="20" height="20" />
                                <rect x="30" y="5" rx="3" ry="3" width="40" height="10" />
                            </ContentLoader>
                            <ContentLoader
                                className='lineContentLoader'
                                height={20}
                                width={'100%'}
                                viewBox={'0 0 200 20'}
                            >
                                <rect x="0" y="0" rx="3" ry="3" width="20" height="20" />
                                <rect x="30" y="5" rx="3" ry="3" width="40" height="10" />
                            </ContentLoader>
                        </div>
                    </Col>
                </Row>
            ));

        }
    });


    const dom = useMemo(() => {
        if (typeof userContext?.isOrganizationAdmin !== 'undefined' && !userContext?.isOrganizationAdmin) {
            return <p>
                {t('Your notifications settings are controlled by the organization\'s Admins')}
                {'.'}
            </p>;
        }

        if (isUpdatingTags) {
            return <div className='notificationPermission'>
                {permissionDom}
            </div>;
        }

        return <>
            {tableDom}
            {footerDom}
        </>;
    }, [tableDom, footerDom, t, userContext]);

    if (!ready) {
        return;
    }

    return <div className='notificationSettings'>
        <h1>{t('Notification Settings')}</h1>
        {dom}
    </div>;
};

export default NotificationSettings;
